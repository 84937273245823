<template>
  <div>
    <OrganismNav class="hidden md:block" :left-menu="desktopLeftMenu" :right-menu="desktopRightMenu" />
    <OrganismNavMobile class="md:hidden" :menu="mobileMenu" :mobile-head-link="mobileHeadLink" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import OrganismNav from '../../organisms/layouts/OrganismNav'
import OrganismNavMobile from '../../organisms/layouts/OrganismNavMobile'

export default {
  name: 'TemplateNav',
  components: {
    OrganismNav,
    OrganismNavMobile,
  },
  data: () => {
    return {
      mobileHeadLink: [
        {
          to: 'https://t.me/encorerus',
          title: 'Telegram',
        },
        {
          to: 'https://instagram.com/encorerus?igshid=MzRlODBiNWFlZA==',
          title: 'Instagram',
        },
        {
          to: 'https://vk.com/encorefitness',
          title: 'VK',
        },
        // {
        //   title: 'en',
        //   to: '/',
        // },
      ],
    }
  },
  computed: {
    ...mapGetters({
      grouppedClubsForClubs: 'modules/content/getClubsAsGrouppedClubsMenu',
      grouppedClubsForTimetable: 'modules/content/getClubsAsGrouppedTimetableMenu',
      grouppedClubsForCabinet: 'modules/content/getClubsAsGrouppedCabinetMenu',
      listClubsForClubs: 'modules/content/getClubsAsListClubsMenu',
      listClubsForTimetable: 'modules/content/getClubsAsListTimetableMenu',
      listClubsForCabinet: 'modules/content/getClubsAsListCabinetMenu',
      listClubsForSpa: 'modules/content/getClubsAsListSpaMenu',
    }),
    desktopLeftMenu() {
      return [
        {
          title: 'клубы',
          to: '/clubs',
          items: this.grouppedClubsForClubs,
        },
        {
          title: 'фитнес',
          to: null,
          items: [
            // {
            //   links: [
            //     {
            //       title: 'Персональный тренинг',
            //       to: '/fitness/personal-training',
            //     },
            //     {
            //       title: 'Групповой тренинг',
            //       to: '/fitness/group-training',
            //     },
            //     {
            //       title: 'Мини-группы',
            //       to: '/fitness/mini-group',
            //     },
            //   ],
            // },
            {
              links: [
                {
                  title: 'Фитнес-тестирование',
                  to: '/fitness/testing',
                },
                // {
                //   title: 'Детский фитнес и секции',
                //   to: '/fitness/moscow/encore_yasenevo/kids-clubs',
                // },
                {
                  title: 'MYZONE®',
                  to: '/fitness/myzone',
                },
              ],
            },
          ],
        },
        // {
        //   title: 'тренеры',
        //   to: '/team',
        //   subLinkFixed: 'sub-not',
        // },
        {
          title: 'spa',
          to: '/spa',
          items: this.listClubsForSpa.reduce((acc, club) => {
            // 2 items in column
            if (acc.length === 0 || acc[acc.length - 1].links.length >= 2) {
              acc.push({ links: [] })
            }
            acc[acc.length - 1].links.push(club)
            return acc
          }, []),
        },
        {
          title: 'расписание',
          to: null,
          class: 'tag',
          subLinkFixed: 'tag-wrap',
          items: this.grouppedClubsForTimetable,
        },
      ]
    },
    desktopRightMenu() {
      return [
        {
          title: 'о нас',
          subLinkFixed: 'about-menu',
          to: null,
          items: [
            {
              links: [
                {
                  title: 'Концепция Encore',
                  to: '/about',
                },
                {
                  title: 'Карьера',
                  to: '/career',
                },
                {
                  title: 'Франшиза',
                  to: '/franchise',
                },
                {
                  title: 'Контакты',
                  to: '/contacts',
                },
              ],
            },
          ],
        },
        {
          title: 'контакты',
          to: '/contacts',
          subLinkFixed: 'sub-not',
        },
        // {
        //   title: 'личный кабинет',
        //   to: null,
        //   items: this.grouppedClubsForCabinet,
        // },
      ]
    },
    mobileMenu() {
      return [
        {
          title: 'главная',
          to: '/',
        },
        {
          title: 'клубы',
          to: '/clubs',
          subTitle: 'все клубы',
          items: this.listClubsForClubs,
        },
        {
          title: 'фитнес',
          to: null,
          items: [
            // {
            //   title: 'Персональный тренинг',
            //   to: '/fitness/personal-training',
            // },
            // {
            //   title: 'Групповой тренинг',
            //   to: '/fitness/group-training',
            // },
            // {
            //   title: 'Мини-группы',
            //   to: '/fitness/mini-group',
            // },
            {
              title: 'Фитнес-тестирование',
              to: '/fitness/testing',
            },
            // {
            //   title: 'Детский фитнес и секции',
            //   to: '/fitness/moscow/encore_yasenevo/kids-clubs',
            // },
            {
              title: 'MYZONE®',
              to: '/fitness/myzone',
            },
          ],
        },
        // {
        //   title: 'тренеры',
        //   to: '/team',
        // },
        {
          title: 'spa',
          to: '/spa',
          subTitle: 'все spa',
          items: this.listClubsForSpa,
        },
        {
          title: 'расписание',
          to: null,
          items: this.listClubsForTimetable,
        },
        {
          title: 'о нас',
          to: null,
          items: [
            {
              title: 'Концепция Encore',
              to: '/about',
            },
            {
              title: 'Карьера',
              to: '/career',
            },
            {
              title: 'Франшиза',
              to: '/franchise',
            },
            {
              title: 'Контакты',
              to: '/contacts',
            },
          ],
        },
        {
          title: 'контакты',
          to: '/contacts',
        },
        // {
        //   title: 'личный кабинет',
        //   to: null,
        //   items: this.listClubsForCabinet,
        // },
      ]
    },
  },
}
</script>
